function importAll(r) {
    r.keys().forEach(r);
}

importAll(require.context("./assets/css/", true, /\.css$/));
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
var contentful = require("contentful");

const noBlogsContainer = document.getElementById("no-blogs-container");
const recentBlogsLoaderElem = document.getElementById("loading-recent-blogs");
const allStoriesBtnElem = document.querySelector(".all_stories_btn");

// initialize contentful client
var client = contentful.createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
});

// Call the function to render blog cards when the page loads
document.addEventListener("DOMContentLoaded", renderBlogCards);

async function fetchRecentBlogPosts() {
    /**
     * Fetch 3 latest blog posts
     */
    try {
        const blogPosts = await client.getEntries({
            content_type: "blog_post",
            order: "-sys.createdAt", // Retrieve in descending order (latest first)
            limit: 3, // Limit the result to 3 entries
        });
        return blogPosts.items;
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
}

// Function to render blog cards with fetched data
async function renderBlogCards() {
    const blogCardsContainerElem = document.getElementById(
        "blog-cards-container"
    );
    const blogPosts = await fetchRecentBlogPosts();

    recentBlogsLoaderElem.remove();

    if (blogPosts.length > 0) {
        // Iterate through the blog posts and create HTML for each card
        blogPosts.forEach(blogPost => {
            let cardHTMLElem = document.createElement("div");
            cardHTMLElem.classList.add("col-lg-4");
            const cardHTML = `
                            <div class="blog_card_wrapper d-flex flex-column">
                                <div class="blog_card_img">
                                    <a href="news-details.html?id=${
                                        blogPost.sys.id
                                    }"><img src="${
                blogPost.fields.header_image.fields.file.url
            }" alt="img"></a>
                                </div>
                                <div class="blog_card_text">
                                    <div class="blog_card_tags"></div>
                                    <div class="blog_card_heading">
                                        <h3><a href="news-details.html?id=${
                                            blogPost.sys.id
                                        }">${blogPost.fields.title}</a></h3>
                                        <p class="blog_card_summary"></p>
                                    </div>
                                    <div class="blog_boxed_bottom_wrapper">
                                        <div class="row">
                                            <div class="">
                                                <div class="blog_bottom_boxed">
                                                    <div class="blog_bottom_icon">
                                                        <i class="fas fa-calendar-alt"></i>    
                                                    </div>
                                                    <div class="blog_bottom_content">
                                                        <h5>Published:</h5>
                                                        <p>${formatDate(
                                                            blogPost.sys
                                                                .createdAt
                                                        )}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    `;

            cardHTMLElem.innerHTML = cardHTML;

            blogPost.fields.tags.forEach(tag => {
                let tagElem = document.createElement("span");
                tagElem.innerHTML = `#${tag.toString().trim()}`;

                // Add blog tag to parent element in card
                cardHTMLElem
                    .querySelector(".blog_card_tags")
                    .appendChild(tagElem);
            });

            // Add blog content field
            cardHTMLElem.querySelector(".blog_card_summary").innerHTML =
                documentToHtmlString(blogPost.fields.content);

            // Append the card HTML Element to the container
            blogCardsContainerElem.appendChild(cardHTMLElem);
        });

        allStoriesBtnElem.style.display = "block";
        
    } else {
        noBlogsContainer.style.display = "flex";
    }
}

function formatDate(dateString) {
    const date = new Date(dateString);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
    );

    // Add the ordinal suffix to the day
    const day = date.getDate();
    const ordinalSuffix = getOrdinalSuffix(day);

    const finalFormattedDate = formattedDate.replace(
        /\b\d{1,2}\b/,
        `${day}${ordinalSuffix}`
    );
    return finalFormattedDate; // Output: 24th November, 2023

    // Function to get the ordinal suffix for the day
    function getOrdinalSuffix(day) {
        if (day >= 11 && day <= 13) {
            return "th";
        }
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }
}
